<template>
  <section id="master-group-belanja">
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <div class="d-flex justify-content-end mb-2">
            <b-button
              class="btn btn-success"
              @click="addTemplate()"
            >
              Tambah Data Master
            </b-button>
          </div>

          <template v-if="alert">
            <b-alert
              :variant="alertDetail.variant"
              class="mt-2"
              show
            >
              <h4 class="alert-heading">
                {{ alertDetail.title }}
              </h4>
              <div
                v-if="alertDetail.variant === 'danger'"
                class="alert-body"
              >
                <ul>
                  <li
                    v-for="(item, index) in alertDetail.text"
                    :key="index"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
              <div
                v-else
                class="alert-body"
              >
                {{ alertDetail.text }}
              </div>
            </b-alert>
          </template>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Action -->
              <div
                v-if="props.column.field == 'action'"
                class="d-flex align-content-middle justify-content-center"
              >
                <b-button
                  variant="info"
                  size="sm"
                  class="mr-25 mb-25"
                  @click="editTemplate(props.row)"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    size="12"
                  /></b-button>
                <b-form-checkbox
                  :checked="(props.row.block == '1') ? true : false"
                  :name="`user-${props.row.userid}`"
                  switch
                  inline
                  @change="changeUser(props.row)"
                />
              </div>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','25','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      ok-variant="success"
      cancel-title="Batal"
      ok-title="Simpan"
      centered
      no-close-on-backdrop
      :title="modalTitle"
      @ok.prevent="validationForm()"
    >
      <b-card-text>
        <template v-if="alert">
          <b-alert
            :variant="alertDetail.variant"
            class="mt-2"
            show
          >
            <h4 class="alert-heading">
              {{ alertDetail.title }}
            </h4>
            <div
              v-if="alertDetail.variant === 'danger'"
              class="alert-body"
            >
              <ul v-if="typeof alertDetail.text === 'object'">
                <li
                  v-for="(item, index) in alertDetail.text"
                  :key="index"
                >
                  {{ index }}:
                  <span
                    v-for="(child, indexs) in item"
                    :key="indexs"
                  >
                    {{ child }}
                  </span>
                </li>
              </ul>
            </div>
            <div
              v-else
              class="alert-body"
            >
              {{ alertDetail.text }}
            </div>
          </b-alert>
        </template>

        <validation-observer ref="simpleRules">
          <b-form>
            <b-form-group
              label="Full Name"
              label-for="vi-fullname"
            >
              <validation-provider
                #default="{ errors }"
                name="Full Name"
                rules="required"
              >
                <b-form-input
                  id="vi-fullname"
                  v-model="fullname"
                  required
                  name="fullname"
                  placeholder="Full Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Username / NIK"
              label-for="vi-username"
            >
              <validation-provider
                #default="{ errors }"
                name="Username / NIK"
                rules="required"
              >
                <b-form-input
                  id="vi-username"
                  v-model="username"
                  required
                  name="username"
                  placeholder="Username"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Phone Number"
              label-for="vi-phonenumber"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone Number"
                rules="required"
              >
                <b-form-input
                  id="vi-phonenumber"
                  v-model="phonenumber"
                  name="phonenumber"
                  placeholder="Phone Number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Email"
              label-for="vi-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="vi-email"
                  v-model="useremail"
                  required
                  name="useremail"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Password"
              label-for="vi-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="confirmed:confirm"
              >
                <b-form-input
                  id="vi-password"
                  v-model="password"
                  type="password"
                  name="password"
                  placeholder="Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Retype-Password"
              label-for="vi-retypepassword"
            >
              <validation-provider
                #default="{ errors }"
                name="Retype Password"
                vid="confirm"
              >
                <b-form-input
                  id="vi-retypepassword"
                  v-model="retypepassword"
                  type="password"
                  name="retypepassword"
                  placeholder="Retype Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Instansi"
              label-for="vi-instansi_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Instansi"
                rules="required"
              >
                <v-select
                  id="vi-instansi_id"
                  v-model="instansi_id"
                  required
                  name="instansi_name"
                  :options="referenceData.ref_instansi"
                  placeholder="Instansi"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Account Group"
              label-for="vi-group_names"
            >
              <validation-provider
                #default="{ errors }"
                name="Account Group"
                rules="required"
              >
                <v-select
                  id="vi-group_names"
                  v-model="group_id"
                  required
                  name="group_names"
                  :options="referenceData.ref_groups"
                  placeholder="Account Group"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Account Division"
              label-for="vi-roles"
            >
              <validation-provider
                #default="{ errors }"
                name="Account Division"
                rules="required"
              >
                <v-select
                  id="vi-group_names"
                  v-model="division_id"
                  required
                  name="roles"
                  :options="referenceData.ref_division"
                  placeholder="Account Division"
                  label="nama"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="edit"
              label="Block User"
              label-for="block"
            >
              <b-form-checkbox
                v-model="block"
                name="block"
                switch
                inline
              />
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      cancel-variant="outline-secondary"
      ok-variant="warning"
      cancel-title="Batal"
      ok-title="Ya, Hapus!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @cancel="clearDeleteItem()"
      @ok="deleteItem()"
    >
      <b-card-text>
        Hapus Akses Pengguna?
      </b-card-text>
    </b-modal>
  </section>

</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormCheckbox, BForm, BRow, BButton, BCol, BCard, BModal, BPagination, BFormGroup, BFormSelect, BCardText, BAlert, BFormInput,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import { required, email } from '@validations'

export default {
  components: {
    BForm,
    BAlert,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BCardText,
    BButton,
    BCard,
    BModal,
    BRow,
    BCol,
    VueGoodTable,
    BPagination,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role === '10') {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      required,
      email,
      userData: getUserData(),
      referenceData: {
        ref_division: [],
        ref_groups: [],
        ref_instansi_name: [],
      },
      alert: false,
      edit: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      pageLength: 10,
      columns: [
        {
          label: 'Username / NIK',
          field: 'username',
          filterOptions: {
            enabled: true,
            placeholder: 'Nama',
          },
        },
        {
          label: 'Nama',
          field: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: 'Nama',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Email',
          },
        },
        {
          label: 'User Group',
          field: 'group_name',
          filterOptions: {
            enabled: true,
            placeholder: 'User Group',
          },
        },
        {
          label: 'Instansi',
          field: 'instansi_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Instansi',
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      toDelData: '',
      toDelItem: '',
      modalTitle: 'Tambah Akses Pengguna',
      id_data: '',
      fullname: '',
      username: '',
      useremail: '',
      phonenumber: '',
      password: '',
      retypepassword: '',
      instansi_id: '',
      group_id: '',
      division_id: '',
      block: false,
    }
  },
  mounted() {
    this.getData()
    this.getReferences()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.simpanTemplate()
        }
      })
    },
    getData() {
      this.$http.get('/user/list', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.rows = res.data.data
          }
        })
    },
    getReferences() {
      this.$http.get('/user/references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
          }
        })
    },
    clearTemplate() {
      this.id_data = ''
      this.fullname = ''
      this.username = ''
      this.useremail = ''
      this.phonenumber = ''
      this.password = ''
      this.retypepassword = ''
      this.instansi_id = ''
      this.group_id = ''
      this.division_id = ''
      this.block = ''
      this.alert = false
    },
    addTemplate() {
      this.edit = false
      this.clearTemplate()
      this.$refs['modal-komponen'].show()
    },
    editTemplate(data) {
      this.id_data = data.userid
      this.fullname = data.fullname
      this.username = data.username
      this.useremail = data.email
      this.phonenumber = data.phonenumber
      this.instansi_id = {
        instansi_id: data.instansi_id,
        name: data.instansi_name,
      }
      this.group_id = {
        group_id: data.group_id,
        name: data.group_name,
      }
      this.division_id = {
        division_id: data.division_id,
        nama: data.division_name,
      }
      this.block = (data.block === 1)
      this.edit = true
      this.modalTitle = 'Ubah Akses Pengguna'

      this.$refs['modal-komponen'].show()
    },
    simpanTemplate() {
      const formData = {
        fullname: this.fullname,
        username: this.username,
        phonenumber: this.phonenumber,
        email: this.useremail,
        instansi_id: this.instansi_id.instansi_id,
        group_id: this.group_id.group_id,
        division_id: this.division_id.division_id,
      }
      if (this.id_data !== '') {
        formData.userid = this.id_data
      }
      if (this.password !== '') {
        formData.password = this.password
        formData.password_confirm = this.retypepassword
      }

      let target = '/user/new-user'
      const param = {
        token: localStorage.getItem('userToken'),
      }
      if (this.edit) {
        target = '/user/update'
        param.userid = this.id_data
        param.block = (this.block) ? 1 : 0
      }

      this.$http.post(target, formData, {
        params: param,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.alert = true
            this.alertDetail = {
              variant: 'success',
              title: 'Data Saved',
              text: res.data.message,
            }
            this.getData()
            this.$refs['modal-komponen'].hide()

            setTimeout(() => {
              this.clearTemplate()
            }, 2000)
          } else {
            this.alert = true
            this.alertDetail = {
              variant: 'danger',
              title: 'Error Found',
              text: res.data.message,
            }
          }
        })
        .catch(error => {
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
    removeTemplate(value, index) {
      this.toDelData = value
      this.toDelItem = index
      this.$refs['modal-warning'].show()
    },
    clearDeleteItem() {
      this.toDelData = ''
      this.toDelItem = ''
    },
    deleteItem() {
      this.$http.delete(`/users/user/${this.toDelData}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(res => {
          this.rows.splice(this.toDelItem, 1)
          this.clearDeleteItem(res)
        })
    },
    changeUser(appData) {
      const fieldName = `user-${appData.userid}`
      const field = document.querySelector(`input[name=${fieldName}]`)
      const verif = {
        userid: appData.userid,
        block: 0,
      }
      if (field.checked) {
        verif.block = 1
      }
      this.$http.post('/user/user-unblock', verif, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data) {
            this.alert = true
            this.alertDetail = {
              variant: 'success',
              title: 'Data Saved',
              text: res.data.message,
            }

            setTimeout(() => {
              this.alert = false
            }, 1000)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
